import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";
import {useLocation, useNavigate} from "react-router-dom";

import {Link} from "react-router-dom";


class DepositMoney extends React.Component {

    constructor() {
        super();
        this.state = {
            amount: "",
            displayPopup:"none",
            upi2:"",
            upi:"",
            mcc:"",
            selectedApp:"",
            popUpShow:"none",
            upiInfo:[],
            min_amount:0,
            showLoading:"none",
            transactionStep:0,
            formView:"none",
            firstView:"none",
            formName:"",
            tId:"",
            screenshot:"",
            history:[],
            gpayUPI:"",
            gpayUPI2:"",
            gpayNumber:"",
            phonepeUPI:"",
            phonepeUPI2:"",
            phonepeNumber:"",
            paytmUPI:"",
            paytmUPI2:"",
            paytmNumber:"",
            qr:"",
            
            qr_enable:"0",
            gpay_enable:"0",
            phonepe_enable:"0",
            paytm_enable:"0",
            other_enable:"0",
            imb:"0",
        }
    }


    

    componentDidMount() {

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post("https://satta-bajar.com/manage/"+"/api2/get_upi.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    localStorage.setItem("min_deposit", response.data.min_deposit)
                    this.setState({
                        upi:response.data.upi,
                        upi2:response.data.upi2,
                        mcc:response.data.merchant,
                        min_amount:response.data.min_deposit,
                        history:response.data.data,
                        gpayUPI:response.data.gpay_upi,
                        gpayUPI2:response.data.gpay_upi2,
                        gpayNumber:response.data.gpay_number,
                        phonepeUPI:response.data.phonepe_upi,
                        phonepeUPI2:response.data.phonepe_upi2,
                        phonepeNumber:response.data.phonepe_number,
                        paytmUPI:response.data.paytm_upi,
                        paytmUPI2:response.data.paytm_upi2,
                        paytmNumber:response.data.paytm_number,
                        qr:response.data.qr,
                        imb:response.data.imb,
                        upi_gateway:response.data.upi_gateway,
                        
                        qr_enable:response.data.qr_enable,
                        gpay_enable:response.data.gpay_enable,
                        phonepe_enable:response.data.phonepe_enable,
                        paytm_enable:response.data.paytm_enable,
                        other_enable:response.data.other_enable,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        let upi_gateway = async() => {
          
            if(this.state.amount === ""){
                let timerInterval;
                    Swal.fire({
                    html: "Enter valid amount",
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else if(parseInt(this.state.amount) < parseInt(this.state.min_amount)){
                let timerInterval;
                    Swal.fire({
                    html: "Minimum deposit is "+this.state.min_amount,
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else {

            this.setState({
                showLoading:"none",
            })

            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
            form.append("amount", this.state.amount);

            axios
            .post("https://satta-bajar.com/manage/"+`/api2/upi_gateways.php`,form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: (data, headers) => {
                    return form; // this is doing the trick
                },
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    showLoading:"none",
                })
                if (response.data.nosession){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.errorMsg
                    })
                } else {
                    window.open(response.data.payment_url,"_self")
                }
            })
        }
        }

        
        let imb = async() => {
          
            if(this.state.amount === ""){
                let timerInterval;
                    Swal.fire({
                    html: "Enter valid amount",
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else if(parseInt(this.state.amount) < parseInt(this.state.min_amount)){
                let timerInterval;
                    Swal.fire({
                    html: "Minimum deposit is "+this.state.min_amount,
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else {

            this.setState({
                showLoading:"none",
            })

            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
            form.append("amount", this.state.amount);

            axios
            .post("https://satta-bajar.com/manage/"+`/api2/imb_gateways.php`,form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: (data, headers) => {
                    return form; // this is doing the trick
                },
            })
            .then((response) => {
                console.log(response)
                this.setState({
                    showLoading:"none",
                })
                if (response.data.nosession){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.errorMsg
                    })
                } else {
                    window.open(response.data.payment_url,"_self")
                }
            })
        }
        }


        let apiCall = () =>{
            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
            form.append("amount", this.state.amount);

            const baseurl = "https://satta-bajar.com/manage/";
            axios
                .post(`${baseurl}/api2/get_payment_web.php`,form)
                .then((response) => {
                    if(response.data.success === "1"){
                        Swal.fire({
                            toast: true,
                            title: 'Deposit Request',
                            text: "Your deposit request received by our team, our team will review your request in sometime"
                        }).then((result)=>{
                            window.location.href = "/home"
                        })

                    } else {
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        }).then((result)=>{
                            window.location.href = "/home"
                        })
                    }
                })
        }

        let depositKnow = ()=>{
            this.setState({
                displayPopup:"none"
            })
        }

        let setAmount = (e)=>{
            this.setState({
                amount:e,
            })
        }


        let selectApp = (e, step)=>{

            if(this.state.amount === ""){
                let timerInterval;
                    Swal.fire({
                    html: "Enter valid amount",
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else if(parseInt(this.state.amount) < parseInt(this.state.min_amount)){
                let timerInterval;
                    Swal.fire({
                    html: "Minimum deposit is "+this.state.min_amount,
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                    })
            } else {
                if(step == 10){
                    var popUpShow = 'none'
                    this.setState({
                        popUpShow:popUpShow,
                    })
                } else if(step == 0){
                    
                    if(e != ""){
                        var upiInfo = []
                        var popUpShow = ''
                        var transactionStep = 1
                        if(e == "phonepe"){
                            popUpShow = "flex"
                            upiInfo['upi'] = this.state.phonepeUPI;
                            upiInfo['upi2'] = this.state.phonepeUPI2;
                            upiInfo['number'] = this.state.phonepeNumber;
                        } else if(e == "paytm"){
                            popUpShow = "flex"
                            upiInfo['upi'] = this.state.paytmUPI;
                            upiInfo['upi2'] = this.state.paytmUPI2;
                            upiInfo['number'] = this.state.paytmNumber;
                        } else if(e == "gpay"){
                            popUpShow = "flex"
                            upiInfo['upi'] = this.state.gpayUPI;
                            upiInfo['upi2'] = this.state.gpayUPI2;
                            upiInfo['number'] = this.state.gpayNumber;
                        } else if(e == "other"){
                            popUpShow = "flex"
                            upiInfo['upi'] = this.state.upi;
                            upiInfo['upi2'] = this.state.upi2;
                        } else if(e == "qr"){
                            popUpShow = "flex"
                            upiInfo['qr'] = this.state.qr
                        }  else {
                            popUpShow = "none"
                            transactionStep = 0
                        }
    
                        this.setState({
                            selectedApp:e,
                            upiInfo:upiInfo,
                            popUpShow:popUpShow,
                            formView:"none",
                            firstView:"flex",
                        })
                    } else {
                        this.setState({
                            formView:"none",
                            firstView:"flex",
                        })
                    }
    
                } else if(step == 1){
                    this.setState({
                        formView:"flex",
                        firstView:"none",
                    })
                } else if(step == 3){
                    this.setState({
                        showLoading:"block",
                    })
                    
                    handleSubmit();
                
                }
            }

            
            
        }
        let getBase64 = (file, cb)=>{
            if(file == ""){
                cb("")
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    cb(reader.result)
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }
            
        }

        let handleSubmit = async () =>{

            var form = new FormData();

            if(this.state.screenshot == "" && this.state.tId == ""){
                this.setState({
                    showLoading:"none",
                })
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Enter transaction id or attach payment screenshot"
                })
            } else {

                let idCardBase64 = '';
                await getBase64(this.state.screenshot, (result) => {
                     idCardBase64 = result;
                     form.append("session", localStorage.getItem("token"));
                     form.append("mobile", localStorage.getItem("mobile"));
             
                     form.append("image", idCardBase64);
                     form.append("note", this.state.formName);
                     form.append("trans", this.state.tId);
                     form.append("amount", this.state.amount);
    
                         axios
                            .post("https://satta-bajar.com/manage/"+`/api2/manual_request.php`,form, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                transformRequest: (data, headers) => {
                                    return form; // this is doing the trick
                                },
                            })
                            .then((response) => {
                                this.setState({
                                    showLoading:"none",
                                })
                                if (response.data.nosession){
                                    Swal.fire({
                                        toast: true,
                                        title: 'Oops...',
                                        text: "Session Expired"
                                    }).then((e)=>{
                                        localStorage.clear()
                                        window.location.href = "/login";
                                    })
                                }
                                if(response.data.success === "0"){
                                    Swal.fire({
                                        toast: true,
                                        title: 'Oops...',
                                        text: response.data.msg
                                    })
                                } else {
                                    Swal.fire({
                                        title: "Success!",
                                        text: "We received your request and will verify very soon",
                                        icon: "success"
                                        }).then((result) => {
                                            window.location.href = "/home";
                                        });
                                }
                            })
                });
                
            }

          
        }

        let tez = ()=>{
            if (this.state.amount === "" || parseInt(this.state.amount) < this.state.min_amount){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Minimum deposit amount should be "+this.state.min_amount
                })
            } else {
                let tid = Date.now()
                this.setState({
                    displayPopup:""
                })
                window.open("tez://upi/pay?pa="+this.state.upi+"&pn="+process.env.REACT_APP_NAME+"&tn="+process.env.REACT_APP_NAME+"&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            }
        }

        let phonepe = ()=>{
           
            let tid = Date.now()
            
            window.open("upi://pay?pa="+this.state.upiInfo['upi']+"&pn=upipay&tn=upipay&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            
        }

        let paytm = ()=>{
            if (this.state.amount === "" || parseInt(this.state.amount) < this.state.min_amount){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Minimum deposit amount should be "+this.state.min_amount
                })
            } else {
                let tid = Date.now()
                this.setState({
                    displayPopup:""
                })
                window.open("upi://pay?pa="+this.state.upi+"&pn="+process.env.REACT_APP_NAME+"&tn="+process.env.REACT_APP_NAME+"&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            }
        }

        let copyText = (e)=>{
            var textField = document.createElement('textarea')
            textField.innerText = e
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()

            let timerInterval;
            Swal.fire({
            html: "Copied to clipboard.",
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
                clearInterval(timerInterval);
            }
            })
          }

        return (<>
                <div className="RootView">
                    <div className="RootWidth">
                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>
                        <div className="loginDiv" style={{ position:this.state.blockPosition, width:"100%", paddingBottom:"15%"}}>

                        <div className="popUpView" style={{display:this.state.popUpShow}}>
                            <div className="bottomBar">
                                
                                { this.state.selectedApp === 'phonepe' ?    
                                    <h5><img src="../images/phone_pe.png" /> PhonePe </h5>
                                    : "" }
                                    
                                { this.state.selectedApp === 'paytm' ?    
                                    <h5><img src="../images/paytm.png" /> PayTM </h5>
                                    : "" }
                                { this.state.selectedApp === 'gpay' ?    
                                    <h5><img src="../images/googlepay.png" /> Google Pay </h5>
                                    : "" }
                                    
                                { this.state.selectedApp === 'other' ?    
                                    <h5><img src="../images/other.png" /> All UPI </h5>
                                    : "" }

                                { this.state.selectedApp === 'qr' ?    
                                    <h5><img src="../images/qr-code-min.png" /> QR Code </h5>
                                    : "" }

                                <div className="barMainView" style={{display:this.state.firstView}}>
                                { this.state.selectedApp === 'qr' ?   
                                
                              <>
                                <div style={{textAlign:"center", padding:"10px"}}>
                                <h6>HOW TO MAKE A DEPOSIT</h6>
                                <p style={{fontSize:"13px"}}>QR Code ko kisi bhi payment app like phonepe, paytm, gpay se scan kare and payment karne k baad screenshot ya transaction id upload karke request send kar de</p>
                            </div>
                               <img className="qr_image" src={"https://satta-bajar.com/manage/"+"admin/"+this.state.qr} />
                                  </>

                                  : <>
                                    <div style={{textAlign:"center", padding:"10px"}}>
                                        <h6>HOW TO MAKE A DEPOSIT</h6>
                                        <p style={{fontSize:"13px"}}>Open App par click kare yaa upi id or number ko app m daale and payment karne k baad screenshot ya transaction id upload karke request send kar de</p>
                                    </div>

                                    { this.state.upiInfo['upi'] != '' ?
                                     <>  <span>OR</span>
                                    <span className="openAppButton" onClick={()=>{phonepe()}}><img src="../images/open-min.png" />Open App</span>
                                    </>  : "" }
                                    { this.state.selectedApp != 'other' ?
                                    <>  
                                        <span onClick={()=>{copyText(this.state.upiInfo['number'])}} className="openAppButton">{  this.state.upiInfo['number']  }<span style={{marginLeft:"20px", fontSize:"12px"}}><img src="../images/copy-min.png" />COPY</span></span>
                                        <span>OR</span>
                                    </>
                                    : "" }
                                   { this.state.upiInfo['upi2'] != '' ?
                                    <span onClick={()=>{copyText(this.state.upiInfo['upi2'])}} className="openAppButton">{  this.state.upiInfo['upi2']  }<span style={{marginLeft:"20px", fontSize:"12px"}}><img src="../images/copy-min.png" />COPY</span></span>
                                    : "" }
                                </>
                                }
                                    <div className="buttonSection">
                                    <button style={{marginTop:"10px"}} className="redbutton" onClick={()=>{selectApp("", 10)}} >
                                        BACK
                                    </button>

                                    <button style={{marginTop:"10px"}} className="button"  onClick={()=>{selectApp("", 1)}} >
                                        NEXT
                                    </button>
                                    </div>
                                </div>

                                
                                <div className="barMainView" style={{display:this.state.formView}}>

                                <div  style={{padding:"10px 20px", width:"100%"}}>
                                <p style={{width:"100%", marginBottom:"1px", textAlign:"left", fontSize:"12px"}}>Deposit Amount</p>
                                <input className="edittext" type="text"
                                    style={{width:"100%", marginBottom:"10px"}}
                                        name="amount"
                                        value={this.state.amount}
                                        readOnly
                                    />

                                <p style={{width:"100%", marginBottom:"1px", textAlign:"left", fontSize:"12px"}}>Name</p>
                                <input className="edittext" type="text"
                                    style={{width:"100%", marginBottom:"10px"}}
                                        name="formName"
                                        value={this.state.formName}
                                        onChange={(e)=>{this.setState({formName:e.target.value})}}
                                        
                                    />
                                    
                                    <p style={{width:"100%", marginBottom:"0px", textAlign:"left", fontSize:"12px"}}>Transaction ID/TXN</p>
                                <input className="edittext" type="text"
                                    style={{width:"100%", marginBottom:"10px"}}
                                        name="tId"
                                        value={this.state.tId}
                                        onChange={(e)=>{this.setState({tId:e.target.value})}}
                                        
                                    />
                                      <p style={{width:"100%", marginBottom:"1px", textAlign:"center", fontSize:"15px"}}>OR</p>
                                      <p style={{width:"100%", marginBottom:"1px", textAlign:"left", fontSize:"12px"}}>Screenshot</p>
                                <input className="edittext" type="file"
                                    style={{width:"100%", marginBottom:"10px"}}
                                    onChange={(e)=>{this.setState({screenshot:e.target.files[0]})}}
                                        name="screenshot"
                                        
                                    />
                                </div>

                                    <div className="buttonSection">
                                    <button style={{marginTop:"10px"}} className="redbutton" onClick={()=>{selectApp("", 0)}} >
                                        BACK
                                    </button>

                                    <button style={{marginTop:"10px"}} className="button"  onClick={()=>{selectApp("", 3)}} >
                                        NEXT
                                    </button>
                                    </div>
                                </div>

                            </div>
                            </div>


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png"  onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>Deposit Money</span>
                                </div>


                            </div>

                            <div className="currentBalBlock">
                                <span>Current Balance</span>
                                <span>
                                        ₹ {localStorage.getItem("wallet")}
                                        </span>
                            </div>

                            <div style={{padding:"15px"}}>

                                    <span style={{display:"flex", alignItems:"center"}} className="edittext depositBox">₹ <input
                                        style={{width:"100%", padding:"11px", border:"none"}}
                                        className=""
                                        name="amount"
                                        type="number"
                                        value={this.state.amount}
                                        onChange={(e)=>{this.setState({amount:e.target.value})}}
                                        placeholder="Enter Coins"
                                    /> </span>
                                    
                                    <div className="depositCash">
                                        <span onClick={()=>{setAmount("500")}}>
                                        ₹ 500
                                        </span>
                                        <span  onClick={()=>{setAmount("1000")}}>
                                        ₹ 1000
                                        </span>
                                        <span  onClick={()=>{setAmount("5000")}}>
                                        ₹ 5000
                                        </span>
                                    </div>

                                    
                                    <p  className="minimumText">Minimum Deposit ( Rs.{localStorage.getItem("min_deposit")}/- )</p>




                                <div className="upi_apps new_upi_apps">

                                { this.state.imb == '1' ?
                                    <div onClick={()=>{imb()}}>
                                        <img src="../images/other.png" />
                                        <span className="app-name">UPI Auto QR Code</span>
                                    </div>
                                      : "" }

                                { this.state.upi_gateway == '1' ?
                                    <div onClick={()=>{upi_gateway()}}>
                                        <img src="../images/other.png" />
                                        <span className="app-name">UPI Auto QR Code</span>
                                    </div>
                                      : "" }


                                { this.state.qr_enable == '1' ?
                                    <div onClick={()=>{selectApp("qr", 0)}}>
                                        <img src="../images/qr-code-min.png" />
                                        <span className="app-name">QR Code</span>
                                    </div>
                                      : "" }


                                { this.state.gpay_enable == '1' ?
                                    <div onClick={()=>{selectApp("gpay", 0)}}>
                                        <img src="../images/googlepay.png" />
                                        <span className="app-name">Google Pay</span>
                                    </div>
                                      : "" }

                                { this.state.phonepe_enable == '1' ?
                                    <div onClick={()=>{selectApp("phonepe", 0)}}>
                                        <img src="../images/phone_pe.png" />
                                        <span className="app-name">PhonePe</span>
                                    </div>
                                       : "" }
                                  
                                { this.state.paytm_enable == '1' ?
                                    <div onClick={()=>{selectApp("paytm", 0)}}>
                                        <img src="../images/paytm.png" />
                                        <span className="app-name">Paytm</span>
                                    </div>
                                       : "" }
                                       
                                { this.state.other_enable == '1' ?
                                    <div onClick={()=>{selectApp("other", 0)}}>
                                        <img src="../images/other.png" />
                                        <span className="app-name">Other Apps</span>
                                    </div>
                                       : "" }
                                    
                                </div>



                                <div className="upi_apps">
                                    
                                </div>

                                <div className="upi_apps">
                                  
                                  
                                </div>


                                <div style={{padding:"15px"}}>

                                    { this.state.history && this.state.history.map((result) => {
                                        return (
                                            <div className="transaction_block" key={result.sn}>
                                                <div>
                                                    <span className="">{result.remark}</span>
                                                    <span>{result.date}</span>
                                                </div>
                                                <span>{result.amount}</span>
                                            </div>
                                        )})}

                                </div>

                                <div className="confirm-box" style={{display:this.state.displayPopup}}>
                                    <span className="title">Have you completed the payment ?</span>
                                    <div>
                                        <button className="button bg-success" onClick={apiCall}>
                                            <b>Yes</b>
                                        </button>
                                        <button className="button bg-danger" onClick={depositKnow}>
                                            <b>No</b>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>

                    <div  className="colored bottomBarMain" style={{padding:"0", justifyContent:"space-between"}}>
                                <div style={{width:"100%"}}>
                                    <div className="row row-cols-5 homemenu" style={{margin:"0", paddingTop:"4px"}}>
                                        <div className="col">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/money (5)-min.png" />
                                                    <span>Deposit</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/withdrawal (3)-min.png"  />
                                                    <span>Withdraw</span>
                                                </div>
                                            </Link>
                                        </div>
                                    
                                        <div className="col">
                                            <Link to={"/home"}>
                                                <div className="homediv">
                                                    <img src="../images/play-button-arrowhead (2).png" />
                                                    <span>Play</span>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col">
                                            <Link to={"/chart"} state={{url:"https://satta-bajar.com/manage/"+"/api/chart2/getChart.php"}}>
                                                <div className="homediv">
                                                    <img src="../images/pie-chart (5)-min.png" />
                                                    <span>Charts</span>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="col">
                                            <Link to={"/gameHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{padding:"5px"}} />
                                                    <span>History</span>
                                                </div>
                                            </Link>
                                        </div> */}

                                        {/* <div className="col">
                                            <a href={ this.state.whatsapp}>
                                                <div className="homediv">
                                                    <img src="../images/phone (2)-min.png" style={{padding:"5px"}} />
                                                    <span>Whatsapp</span>
                                                </div>
                                            </a>
                                        </div> */}

                                      
                                        <div className="col">
                                        <Link to={"/refer"}>
                                                <div className="homediv">
                                                    <img src="../images/share (6)-min.png" style={{padding:"5px"}} />
                                                    <span>Refer</span>
                                                </div>
                                                </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                                
                            </div>
                </div>


            </>
        );
    }

}

export default DepositMoney;

