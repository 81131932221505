import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            showLoading: "block",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post("https://satta-bajar.com/manage/"+"/api2/transaction_history.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        showLoading:"none",
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        
                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Transactions</span>
                                </div>

                              

                            </div>


                        
                           
                            <div style={{padding:"15px"}}>
                               

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block" key={result.sn}>
                                            <span className="date-blocks">{result.date}</span>
                                            <div>
                                                <span className="">{result.remark}</span>
                                                <span className={"trans"+result.type}>{result.amount}</span>
                                            </div>
                                            
                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
