import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";

const Home = () => {

    const [amount, setamount] = useState("");

    const [paytm, setpaytm] = useState("");

    let apiCall = () =>{

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("user", localStorage.getItem("mobile"));

        form.append("amount", amount);
        form.append("mobile", paytm);

        axios
            .post("https://satta-bajar.com/manage/"+`/api2/transfer.php`,form)
            .then((response) => {
                if(response.data.success === "0"){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg,
                    })
                } else {
                    Swal.fire({
                        toast: true,
                        title: 'Transfer Request',
                        text: response.data.msg,
                    })
                }
            })

    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>Transfer Money</span>
                            </div>
                        </div>

                        <div style={{padding:"15px"}} className="withdraw-form">

                            <input
                                style={{width:"100%", marginTop:"15px"}}
                                className="edittext"
                                name="amount"
                                type="number"
                                value={paytm}
                                onChange={(e)=>{setpaytm(e.target.value)}}
                                placeholder="Enter Mobile Number"
                            />

                            <input
                                style={{width:"100%", marginTop:"10px"}}
                                className="edittext"
                                name="amount"
                                type="number"
                                value={amount}
                                onChange={(e)=>{setamount(e.target.value)}}
                                placeholder="Enter Coins"
                            />

                            <button className="button" onClick={apiCall}>
                                <b>Transfer Coins</b>
                            </button>


                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
