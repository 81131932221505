import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import CryptoJS from "crypto-js";

import Swal from "sweetalert2";
import {Link} from "react-router-dom";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            type: "",
            wallet: "",
            showLoading: "block",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        var encrypted = CryptoJS.AES.encrypt("https://satta-bajar.com/manage/"+"/api2/games.php", window.location.href);
        console.log(encrypted.toString())

        // U2FsdGVkX18g6/vuGGV2r1UDOGrc7wYOZIgXrjnmIackUXF6oOymMDsvpuTz8NIAqSLB3PpMko6sPYI/axYWPA==

        axios
            .post("https://satta-bajar.com/manage/"+"/api2/games.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        showLoading:"none",
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            }).catch((error) => {
                this.setState({
                    showLoading:"none",
                    history:[]
                })
            })
    }


    render() {


        let loginSubmit = (bet_id) =>{

       
            Swal.fire({
                title: "Are you sure you wanted to delete this bet ?",
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `No`
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                
    
            var form = new FormData();
    
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
    
            form.append("bet_id", bet_id);
    
            axios
                .post("https://satta-bajar.com/manage/"+`/api2/delete_bet.php`,form)
                .then((response) => {
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if(response.data.success === "0"){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        })
                    } else {
                        Swal.fire({
                            title: "Success!",
                            text: "Your game deleted succesfully, Refund added to your wallet",
                            icon: "success"
                        }).then((result) => {
                            window.location.reload(); 
                        });
                    }
                })
             
            } else if (result.isDenied) {
            
            }
          });
        }
        
        let getGameName = (e) => {
            if(e.endsWith("_A")){
                return "ANDAR"
            } else if(e.endsWith("_B")){
                return "BAHAR"
            } else {
                return "JODI";
            }
        }
    
        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>
                    

                        <div className="loginDiv" style={{ position:this.state.blockPosition, width:"100%", paddingBottom:"15%"}}>


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Game History</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block borderdiv" style={{marginBottom:"10px"}} key={result.sn}>
                                            <span className="date-blocks" style={{fontSize:"16px"}}>
                                                {result.bazar.replace("_BA"," BA").replace("_A"," ").replace("_B"," ").replace("_"," ")}
                                                
                                                </span>
                                            <div style={{padding:"6px"}}>
                                                <div className="game-sections">
                                                    <span className="title">Game Type</span>
                                                    
                                                    <span className="data">
                                                    { getGameName(result.bazar) }
                                                        </span>
                                                </div>
                                                <div className="game-sections">
                                                    <span className="title">Digit</span>
                                                    <span className="data">{result.number}</span>
                                                </div>
                                                <div className="game-sections">
                                                    <span className="title">Point</span>
                                                    <span className="data">{result.amount}</span>
                                                </div>
                                            </div>
                                            
                                            <span className="game-date">{result.date}</span>

                                            { result.is_delete == "1" ? (
                               

                                             <div className="game-date betdelete" onClick={()=>{loginSubmit(result.sn)}}>
                                                <img src="../images/delete.png" />
                                                Delete</div>

                                            ) : "" }
                                        </div>
                                    )})}


                            </div>

                        </div>

                        <div  className="colored bottomBarMain" style={{padding:"0", justifyContent:"space-between"}}>
                                <div style={{width:"100%"}}>
                                    <div className="row row-cols-5 homemenu" style={{margin:"0", paddingTop:"4px"}}>
                                        <div className="col">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/money (5)-min.png" />
                                                    <span>Deposit</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/withdrawal (3)-min.png"  />
                                                    <span>Withdraw</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/home"}>
                                                <div className="homediv">
                                                    <img src="../images/play-button-arrowhead (2).png" />
                                                    <span>Play</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/chart"} state={{url:"https://satta-bajar.com/manage/"+"/api/chart2/getChart.php"}}>
                                                <div className="homediv">
                                                    <img src="../images/pie-chart (5)-min.png" />
                                                    <span>Charts</span>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="col">
                                            <Link to={"/gameHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{padding:"5px"}} />
                                                    <span>History</span>
                                                </div>
                                            </Link>
                                        </div> */}

                                        {/* <div className="col">
                                            <a href={ this.state.whatsapp}>
                                                <div className="homediv">
                                                    <img src="../images/phone (2)-min.png" style={{padding:"5px"}} />
                                                    <span>Whatsapp</span>
                                                </div>
                                            </a>
                                        </div> */}

                                      
                                        <div className="col">
                                        <Link to={"/refer"}>
                                                <div className="homediv">
                                                    <img src="../images/share (6)-min.png" style={{padding:"5px"}} />
                                                    <span>Refer</span>
                                                </div>
                                                </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
