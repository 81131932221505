import {useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import Iframe from "./Iframe";
import {Link} from "react-router-dom";

const Chart = () => {

    let location = useLocation();
    let url = location.state.url;
    
    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv" style={{ paddingBottom:"15%"}}>


                        <div className="toolbar">
                            <div>
                                <img src="../images/back.png"  onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>Chart</span>
                            </div>


                        </div>

                        <Iframe source={url} />

                    </div>

                    <div  className="colored bottomBarMain" style={{padding:"0", justifyContent:"space-between"}}>
                                <div style={{width:"100%"}}>
                                    <div className="row row-cols-5 homemenu" style={{margin:"0", paddingTop:"4px"}}>
                                        <div className="col">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/money (5)-min.png" />
                                                    <span>Deposit</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/withdrawal (3)-min.png"  />
                                                    <span>Withdraw</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/home"}>
                                                <div className="homediv">
                                                    <img src="../images/play-button-arrowhead (2).png" />
                                                    <span>Play</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link to={"/chart"} state={{url:"https://satta-bajar.com/manage/"+"/api/chart2/getChart.php"}}>
                                                <div className="homediv">
                                                    <img src="../images/pie-chart (5)-min.png" />
                                                    <span>Charts</span>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="col">
                                            <Link to={"/gameHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{padding:"5px"}} />
                                                    <span>History</span>
                                                </div>
                                            </Link>
                                        </div> */}

                                        {/* <div className="col">
                                            <a href={ this.state.whatsapp}>
                                                <div className="homediv">
                                                    <img src="../images/phone (2)-min.png" style={{padding:"5px"}} />
                                                    <span>Whatsapp</span>
                                                </div>
                                            </a>
                                        </div> */}

                                      
                                        <div className="col">
                                        <Link to={"/refer"}>
                                                <div className="homediv">
                                                    <img src="../images/share (6)-min.png" style={{padding:"5px"}} />
                                                    <span>Refer</span>
                                                </div>
                                                </Link>
                                        </div>
                                       
                                    </div>
                                </div>
                                
                            </div>

                </div>
            </div>


        </>
    );
};

export default Chart;
