import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            history:[]
        }
    }

    componentDidMount() {
        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post("https://satta-bajar.com/manage/"+"/api2/notifications.php", form)
            .then((response) => {
                if(response.status === 200){
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Notifications</span>
                                </div>
                            </div>

                            <div style={{padding:"15px"}}>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block borderdiv" style={{marginBottom:"10px"}} key={result.sn}>
                                            <div>
                                                <span style={{fontSize:"13px"}}>{result.date}</span>
                                                <span>{result.title}</span>
                                                <span>{result.msg}</span>
                                            </div>
                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
