import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class GameRates extends React.Component {

    constructor() {
        super();
        this.state = {
            mobile: "",
            name: "",
            email: "",
            password:"",
            confirm_pass:""
        }
    }

    componentDidMount() {


        let form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post("https://satta-bajar.com/manage/"+"/api2/get_profile.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    this.setState({
                        mobile:response.data.mobile,
                        email:response.data.email,
                        name:response.data.name
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }



    render() {



        let passUpdate = () =>{
            if (this.state.password !== this.state.confirm_pass) {
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Confirm password does not match"
                })
            } if (this.state.password === "") {
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Enter valid password"
                })
            } else {
                var form = new FormData();
                form.append("session", localStorage.getItem("token"));
                form.append("mobile", localStorage.getItem("mobile"));
                form.append("pass", this.state.password);

                axios
                    .post("https://satta-bajar.com/manage/"+`/api2/password.php`, form)
                    .then((response) => {
                        if (response.data.success === "0") {
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: response.data.msg,
                            })

                        } else {
                            Swal.fire({
                                toast: true,
                                title: 'Password Updated',
                                text: "Your Password updated successfully"
                            })
                        }
                    })

            }
        }


        let profileUpdate = () =>{
                var form = new FormData();
                form.append("session", localStorage.getItem("token"));
                form.append("mobile", localStorage.getItem("mobile"));
                form.append("name", this.state.name);
                form.append("email", this.state.email);

                axios
                    .post("https://satta-bajar.com/manage/"+`/api2/profile.php`, form)
                    .then((response) => {
                        if (response.data.success === "0") {
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: response.data.msg,
                            })

                        } else {
                            Swal.fire({
                                toast: true,
                                title: 'Profile Updated',
                                text: "Your profile updated successfully"
                            })
                        }
                    })
        }

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png" alt="back" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>My Profile</span>
                                </div>


                            </div>

                            <div style={{padding:"5px"}} className="frameForm">

                                <div className="main-game-screen">
                                    <span className="pageTitle" style={{paddingBottom:"10px"}}>Profile Information</span>
                                    <input
                                        style={{marginTop:"10px"}}
                                        className="edittext"
                                        name="mobile"
                                        type="number"
                                        value={this.state.mobile}
                                        disabled={true}
                                    />
                                    <input
                                        className="edittext"
                                        name="name"
                                        type="text"
                                        value={this.state.name}
                                        onChange={(e)=>{this.setState({name:e.target.value})}}
                                        placeholder="Enter Full Name"
                                    />
                                    <input
                                        className="edittext"
                                        name="email"
                                        type="email"
                                        value={this.state.email}
                                        onChange={(e)=>{this.setState({email:e.target.value})}}
                                        placeholder="Enter Email Address"
                                    />
                                    <button className="button" onClick={profileUpdate}>
                                        <b>Update Profile</b>
                                    </button>
                                </div>

                                <div className="main-game-screen">
                                    <span className="pageTitle">Update Password</span>
                                    <input
                                        style={{marginTop:"10px"}}
                                        className="edittext"
                                        name="mobile"
                                        type="password"
                                        value={this.state.password}
                                        onChange={(e)=>{this.setState({password:e.target.value})}}
                                        placeholder="Enter Password"
                                    />
                                    <input
                                        className="edittext"
                                        name="name"
                                        type="password"
                                        value={this.state.confirm_pass}
                                        onChange={(e)=>{this.setState({confirm_pass:e.target.value})}}
                                        placeholder="Confirm Password"
                                    />

                                    <button className="button" onClick={passUpdate}>
                                        <b>Update Password</b>
                                    </button>
                                </div>



                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default GameRates;
